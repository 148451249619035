.o-adm-sound{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__play{
   cursor: pointer;
  }
  &__pause{
    display: none;
   cursor: pointer;
  }
}