.o-adm{
  color: #000;
  padding: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include mq($from: tablet) {
    flex-wrap: nowrap;
  }
  &-item{
    width: 100%;
    margin-top: 50px;
    @include mq($from: tablet) {
      width: 45%;
    }
    .total{
      display: flex;
    }
    .sounds{
     .item-wrapper{
      margin-top: 24px;
      display: flex;
      align-items: center;
     }
      .sound-item{
        .sounds{
          padding: 10px;
          width: 70%;
          border: 1px solid rebeccapurple;
          cursor: pointer;
        }
      }
      
    }
    .text-styles{
      padding: 10px;
      border: 1px solid rebeccapurple;
      margin-bottom: 12px;
    }
    .texts-wrapper{
      margin-top: 24px;
    }
  }
  .load-more{
    padding: 15px;
    border: 1px solid black;
    width: fit-content;
    cursor: pointer;
    margin-top: 30px;
  }
  .download-sound{
    display: block;
    position: relative;
    margin-left: 12px;
    .btn-image{
      width: 25px;
      height: 25px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}