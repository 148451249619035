.o-intro {
  @include flex(center, center);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 105;
  background-size: cover;
  background-color: #dbdde0;
  background-position: 100%;
  overflow: hidden;

  &__content {
    width: 100%;
    opacity: 0;

    &-inner {
      margin: 0 auto;
      padding: 0 22px;
      max-width: 375px;

      @include mq(desktop) {
        max-width: 600px;
      }
    }

    &.center {
      opacity: 1;
    }

    &.left {
      opacity: 0;
    }

    &-title {
      font-family: 'DidactGothic', sans-serif;
      font-weight: 500;
      font-size: 72px;
      line-height: 55px;
      text-transform: lowercase;
      color: #ee4323;
      max-width: 550px;
      margin-top: 0;
      margin-bottom: 20px;
      text-align: left;

      @include mq(desktop) {
        font-size: 120px;
        line-height: 90px;
      }

      span {
        display: block;
        text-align: right;
      }
    }

    &-description {
      width: 100%;
      margin-left: 4px;
      margin-top: 8px;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: #2c2d64;
      max-width: 275px;
      font-family: 'Montserrat', sans-serif;
      text-align: left;

      @include mq(desktop) {
        font-size: 24px;
        line-height: 28px;
      }

      span {
        &:first-child {
          margin-left: 73px;
          display: inline-block;
        }
        &:last-child {
          display: block;
          text-align: left;
        }
      }
    }
  }

  @include mq(desktop) {
    background-position: 140% 0;
  }

  &.animate {
    transition: background-position 6s ease-in-out;

    .o-intro__content {
      transition: all 0.4s ease-in;

      @include mq(desktop) {
        transition: all 0.4s ease-in;

        &.left {
          transition: all 0.4s ease-in;
        }
      }
    }
  }
}
