.o-audio-recorder {
  //margin-top: get-space(m);
  @include flex-column(center, center);
  flex-grow: 1;

  &__save-button {
    background: get-color(yellow);
    min-height: 115px;
    width: 274px;
    border: none;
    outline: none;
    margin: 40px auto 0;
    border-radius: 3px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    color: get-color(purple);
    transition: all 0.15s ease-in-out;

    @include mq(desktop) {
      min-height: 80px;
    }

    &[disabled='true'] {
      background: #b7c9d1;
      opacity: 0.54;
      color: rgba(#2c2d64, 0.25);
    }

    &:active {
      &:not(&[disabled='true']) {
        opacity: 0.7;
      }
    }
  }
  &__email-block{
    width: 100%;
    display: none;
    margin: 12px 0 0px;
    .error{
      display: none;
      margin-top: 4px;
      font-size: 10px;
      color: red;
    }

  }
  &__email{
    background: get-color(gray);
    padding: 4px;
    border-radius: 3px;
    border: none;
    resize: none;
    outline: none;
    font-family: 'DidactGothic', sans-serif;
    color: get-color(purple);
    user-select: initial !important;
    -webkit-user-select: initial !important;
    -webkit-touch-callout: initial !important;

   
  }
  &__checkbox{
    width: 100%;
  }
  &__textarea {
    background: get-color(gray);
    border-radius: 3px;
    border: none;
    resize: none;
    outline: none;
    padding: 15px 20px;
    width: 100%;
    height: 150px;
    font-family: 'DidactGothic', sans-serif;
    font-size: 26px;
    line-height: 34px;
    color: get-color(purple);
    user-select: initial !important;
    -webkit-user-select: initial !important;
    -webkit-touch-callout: initial !important;

    @include mq(desktop) {
      width: 100%;
    }

    &-block {
      margin: 0 0 12px;

      @include mq(desktop) {
        width: 100%;
      }
    }

    &-warning {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      margin-bottom: 4px;

      color: #e3e3e3;
    }

    &-wrapper {
      margin-top: 60px;
      @include flex-column(space-around);
      padding: 0 16px;
      display: none;

      @include mq(desktop) {
        width: 100%;
      }

      &.visible {
        @include flex-column(space-around, center);
      }
    }
  }

  &__content {
    @include flex-column(center);
    flex-grow: 1;
    max-width: 650px;
    transition: all 0.15s ease-in-out;

    @include mq(desktop) {
      width: 100%;
    }

    &--hidden {
      opacity: 0;
    }
  }

  &__loading-content {
    @include flex-column(center, center);
    text-align: center;
    padding: 0 30px;
    width: 162px;
    height: 162px;
    border: 7px solid get-color(gray);
    border-radius: 50%;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: get-color(purple);
    opacity: 0;
    display: none;
    transition: all 0.15s ease-in-out;

    &--visible {
      opacity: 1;
    }
  }

  &__button {
    outline: none;
    position: relative;
    background: get-color(yellow);
    border: none;
    cursor: pointer;
    height: 135px;
    width: 135px;
    border-radius: 50%;
    margin-bottom: 10px;

    &-image {
      user-select: none;
      -webkit-user-select: none !important;
      -webkit-touch-callout: none !important;
      height: 80px;
      color: get-color(purple);
    }
  }

  &__recorder-wrapper {
    @include flex-column(center, center);
    font-size: 14px;
    margin-top: 80px;
    line-height: 17px;
    text-align: center;
    color: #eaeaea;
    display: none;

    &.visible {
      display: flex;
    }
  }

  &__title {
    margin: 58px auto 0;
    font-family: 'DidactGothic', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 37px;
    text-align: center;
    color: get-color(purple);

    max-width: 375px;

    @include mq(desktop) {
      max-width: 450px;
    }

    &-wrapper {
      @include flex-column(flex-end);
      opacity: 0;
    }
  

    &-dots {
      margin: 22px 20px 44px;
      @include flex(flex-end);

      &-dot {
        cursor: pointer;
        height: 10px;
        width: 10px;
        background: get-color(gray);
        opacity: 0.5;
        border-radius: 50%;

        &:not(:last-child) {
          margin-right: 4px;
        }

        &--active {
          opacity: 1;
          background: get-color(yellow);
        }
      }
    }
  }

  &__count {
    font-family: 'DidactGothic', sans-serif;
    font-size: 36px;
    color: get-color(yellow);
    margin-bottom: 40px;
  }

  &__recorder-title {
    @include flex-column;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: get-color(purple);

    @include mq(desktop) {
      &--mobile {
        display: none;
      }
    }

    &--desktop {
      display: none;
      @include mq(desktop) {
        display: block;
      }
    }

    &--record {
      margin-top: 0;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__circle {
    position: relative;
  }

  .circle-progress-value {
    stroke-width: 6px;
    stroke: get-color(gray);
  }

  .circle-progress {
    position: absolute;
    left: -6px;
    top: -6px;
    width: 147px !important;
    height: 147px !important;
  }

  .circle-progress-circle {
    stroke-width: 6px;
    stroke: get-color(yellow);
  }

  .circle-progress-text {
    opacity: 0;
  }
  &__recorder-tooltip{
      text-align: left;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #2c2d64;
    background: #dbdcdd;
    border-radius: 3px;
    padding: 19px 9px 14px 20px;
    width: 306px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    &-visible{
      opacity: 1;
      visibility: visible;
    }
  }
  .countdown{
    display: flex;
    justify-content: flex-end;
  }
}
