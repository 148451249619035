.miphs {
  width: 100vw;
  min-height: 100vh;
  background: #dbdcdd;
  overflow: auto;
}

.o-miphs {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 375px;
  padding: 0 20px;
  margin: 50px auto 0 auto;
  font-family: 'Montserrat', sans-serif;
  @include mq($from: tablet) {
    max-width: 1170px;
    margin: 0 98px;
    padding: 0;
    margin-top: 80px;
  }

  &__title {
    font-family: 'DidactGothic', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 58px;
    line-height: 82.6%;
    text-transform: lowercase;
    color: #ee4323;
    margin-bottom: 30px;
    @include mq($from: tablet) {
      max-width: 340px;
      margin-bottom: 20px;
    }
  }
  &__subtitle {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #2c2d64;
    margin-bottom: 64px;
    @include mq($from: tablet) {
      max-width: 714px;
      width: 100%;
      margin-bottom: 30px;
    }
  }
}
