.o-header {
  display: flex;
}
.header-substrate {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #000;
  opacity: 0.5;
  z-index: 11;
  visibility: hidden;
  transition: all 0.3s ease;
  display: none;
  @include mq($from: tablet) {
    display: none;
  }
}

.header {
  user-select: none;
  z-index: -1;
  max-width: 375px;
  right: 50%;
  transform: translateX(50%);
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 20px;
  background: transparent;

  &.filled {
    transition: all 0.3s ease 0.35s;
    background: #dbdcdd;
  }

  @include mq($from: tablet) {
    max-width: 100%;
    padding: 15px 98px;
  }
  &-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__icon {
      width: 24px;
      height: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__text {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: #2c2d64;
      margin-right: 6px;
      transition: all 0.3s ease;
    }
  }
}

.visible {
  opacity: 1;
}

.info {
  position: relative;
  display: block;
  outline: none;

  .tooltip {
    position: absolute;
    display: none;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #2c2d64;
    background: #dbdcdd;
    border-radius: 3px;
    padding: 19px 9px 14px 20px;
    width: 306px;
    left: 0;
    top: 40px;
  }
  .visible {
    display: block;
  }

  &__icon {
    cursor: pointer;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }
}

.menu {
  display: flex;
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  color: white;
  font-size: 20px;
  flex-direction: column;
  justify-content: center;
  padding-top: 25px;
  visibility: hidden;
  @include mq($from: tablet) {
    width: 40vw;
    right: 0;
    left: initial;
    transform: translate(100%, 0);
    transition: all 0.5s ease;
    background: get-color(blue);
  }

  &-item {
    min-height: 80px;
    background: #dbdcdd;
    border-radius: 3px;
    margin-bottom: 25px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    padding: 0;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;
    color: #2c2d64;

    .text {
      font-size: 22px;
      line-height: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__icon {
    width: 25px;
    height: 25px;
    fill: #2c2d64;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .share {
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
}
.menu-animation {
  @include mq($from: tablet) {
    transform: translate(0, 0);
  }
}
.current {
  opacity: 0.3;
  color: #94b6c5;
  pointer-events: none;
}

.back {
  outline: none;
  background: none;
  border: none;
  display: none;
}
