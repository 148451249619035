@import '~tiny-slider/src/tiny-slider.scss';

.tns-outer button {
  display: none;
}

html,
body,
div,
article,
section,
main,
footer,
header,
form,
fieldset,
legend,
pre,
code,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
textarea,
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='url'] {
  box-sizing: border-box;
}

html {
  // Prevents iOS text size adjust after orientation change, without disabling
  text-size-adjust: 100%;

  // Optimize text rendering speed
  text-rendering: optimizeSpeed;
}

body {
  color: get-color(grey);
  font-family: $font-family-default;
  background: #dbdde0;


  // enable font smoothing http://szafranek.net/works/articles/font-smoothing-explained/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
 

  

  & > .ripple {
    z-index: 15!important;
  }

  //height: 100vh;
  //width: 100vw;
  //
  //@media (orientation: portrait) {
  //  position: fixed;
  //  overflow: hidden;
  //}

  //&.relative {
  //  position: relative;
  //  overflow: scroll;
  //}
}

// Reset default UA Styles for typographical elements
figure {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  position: relative;
}

.lazyload,
.lazyloading {
  opacity: 0;
  transition: opacity 400ms;
}

img.lazyloaded {
  transition: opacity 400ms;
  opacity: 1;
}


iframe {
  margin-top: 58px;
  border: none;
  height: 100%;
  width: 100%;

  @include mq(tablet) {
    margin-top: 0;
  }
}