.o-statistic{
  flex-grow: 1;
  margin-top: 24px;
  @include mq($from: tablet) {
    max-width: 1170px;
    margin: 0 98px;
    padding-top: 80px;
  }

  &__content{
    display: flex;
    flex-direction: column;
    max-width: 375px;
    padding: 0 10px;
    flex-grow: 1;
    justify-content: center;
    margin: 0 auto;
    overflow: auto;
    @include mq($from: tablet) {
      max-width: 100%;
      width: 725px;
      margin: initial;
      padding: 0;
    }
  }
  &__link {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.065em;
    text-transform: uppercase;

    color: #2c2d64;
    border: none;
    outline: none;
    cursor: pointer;
    width: 274px;
    min-height: 115px;
    letter-spacing: 0.065em;

    border-radius: 3px;
    background: get-color(blue);
    font-family: Montserrat, sans-serif;
    margin: 0 auto;

    @include mq(desktop) {
      margin: 0;
      @include flex(null, center);
    }
  }
  .title{
    font-family: 'DidactGothic', sans-serif;
    font-weight: 500;
    color: #ee4323;
    margin: 24px 0;
    font-size: 3.2rem;
    line-height: 3rem;
    @include mq($from: tablet) {
      margin-bottom: 30px;
    }
  }
  .text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2c2d64;
    span {
      display: block;
    }
    @include mq($from: tablet) {
      margin-bottom: 10px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    a {
      font-style: italic;
      border-bottom: 1px solid #000;
    }
  }
  .btn-text{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.0625rem;
    line-height: 1.375rem;
    color: #2c2d64;
    margin-bottom: 12px;
  }
  .images{
    // display: flex;
    // justify-content: space-between;
    margin: 24px 0;
    .image-wrapper{
      width: 100%;
      margin-bottom: 24px;
      @include mq($from: tablet) {
        width: 65%;
      }
      &:last-child{
        margin-bottom: 0;
      }
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .partners-description {
    padding: 33px 14px 33px 23px;
    background: #94b6c5;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #2e2e30;
    a {
      border-bottom: 1px solid #000;
    }
    @include mq($from: tablet) {
      padding: 33px 98px;
    }
  }
  .partners {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    background: #fff;
    padding: 10px 0;
    @include mq($from: tablet) {
      padding: 20px 98px 20px 98px;
    }
  
    .item {
      grid-column: span 3;
      margin-bottom: 10px;
      margin: 5px 10px 5px 10px;
      height: 35px;
      width: 60px;
      &:nth-child(3) {
        img {
          margin-top: 5px;
        }
      }
      @include mq($from: tablet) {
        grid-column: span 1;
        &:first-child {
          margin-left: 0;
        }
      }
  
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .links-title {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 22px;
      color: #2c2d64;
    }
    .item-wrapper {
      display: flex;
      justify-content: center;
      margin: 25px 0;
    }
    .links-item {
      margin: 0 40px;
      
      @include mq($from: tablet) {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .share{
      cursor: pointer;
      width: 39px;
      height: 39px;
      img{
        width: 100%;
        height: 100%;
      }
    
  }
  
}
}

