.o-about {
  background: #98bac9;
  flex-grow: 1;
  margin-top: 24px;
  @include mq($from: tablet) {
    max-width: 1170px;
    margin: 0 98px;
    padding-top: 80px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 375px;
    padding: 0 10px;
    flex-grow: 1;
    justify-content: center;
    margin: 0 auto;
    overflow: auto;
    @include mq($from: tablet) {
      max-width: 100%;
      width: 725px;
      margin: initial;
      padding: 0;
    }
  }

  .title-composition {
    font-family: 'DidactGothic', sans-serif;
    font-weight: 500;
    font-size: 66px;
    line-height: 50px;
    color: #ee4323;
    text-transform: lowercase;
    margin: 24px 0;
    span {
      display: block;
      margin: 5px 0;
    }
    .right {
      text-align: right;
    }
    @include mq($from: tablet) {
      margin-bottom: 30px;
      max-width: 350px;
    }
  }

  .text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2c2d64;
    span {
      display: block;
    }
    @include mq($from: tablet) {
      margin-bottom: 10px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    a {
      font-style: italic;
      border-bottom: 1px solid #000;
    }
  }

  .links {
    display: flex;
    flex-direction: column;

    .links-title {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 22px;
      color: #2c2d64;
    }
    .item-wrapper {
      display: flex;
      justify-content: center;
      margin: 25px 0;
    }
    .links-item {
      margin: 0 40px;
      
      @include mq($from: tablet) {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  
  }
}
.share{
  cursor: pointer;
  width: 39px;
  height: 39px;
  img{
    width: 100%;
    height: 100%;
  }

}
.navigation {
  .aspect-ratio__wrapper{
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    margin-bottom: 63px;
    &:last-child {
      margin-bottom: 32px;
    }
  }
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 0;
    height: 100%;
    max-width: 100%;
    @include mq($from: tablet){
      width: 100%;
      height: 100%;
    }
    
  }
  .item-description {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #2c2d64;
    margin-bottom: 10px;
    font-weight: normal;
  }

  &-item {
    align-items: center;
    padding: 0;
    margin-bottom: 63px;
    width: 100%;
    outline: none;
    border: none;

    &__image {
      width: 100%;
      position: relative;
      cursor: pointer;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;

    
      &::before{
        content: '';
        position: absolute;
        right: 15px;
        bottom: 15px;
        background: url('/dummy/images/link.svg');
        height: 30px;
        width: 30px;
        z-index: 2;
      }
      .item-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(#000,.4);
        width: 100%;
        height: 100%;
        z-index: 1;
       
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.partners-description {
  padding: 33px 14px 33px 23px;
  background: #94b6c5;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #2e2e30;
  a {
    border-bottom: 1px solid #000;
  }
  @include mq($from: tablet) {
    padding: 33px 98px;
  }
}
.partners {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background: #fff;
  padding: 10px 0;
  @include mq($from: tablet) {
    padding: 20px 98px 20px 98px;
  }

  .item {
    grid-column: span 3;
    margin-bottom: 10px;
    margin: 5px 10px 5px 10px;
    height: 35px;
    width: 60px;
    &:nth-child(3) {
      img {
        margin-top: 5px;
      }
    }
    @include mq($from: tablet) {
      grid-column: span 1;
      &:first-child {
        margin-left: 0;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
