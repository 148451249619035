// Fonts
$font-family-default: Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, serif;

$text-types-default: (
  main: (
    lineHeight: 1,
    fontSize: 45px,
    m: (
      lineHeight: 1,
      fontSize: 35px
    )
  ),
  sub: (
    lineHeight: 1.25,
    fontSize: 25px,
    m: (
      lineHeight: 1.25,
      fontSize: 30px
    )
  ),
  copy: (
    lineHeight: 1,
    fontSize: 12px,
    m: (
      lineHeight: 1,
      fontSize: 14px
    )
  )
);

// Get text property from a configuration file
// @type {String} $type Text type e.g. headline
// @property {String} $property Text property from configuration file
// @text-types-default {map} $property Text properties configuration
// @text-types-default {map} $property Text properties configuration
// @return {*} Nested content
@function get-text-property($type, $property, $breakpoint: null, $text-types: $text-types-default) {
  @if $breakpoint {
    @return map-nested-get($text-types, $type, $breakpoint, $property);
  } @else {
    @return map-nested-get($text-types, $type, $property);
  }
}
