.l-container {
  &__wrapper {
    //max-width: $gridMaxWith;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    //padding: 15px 15px 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    //.sg-debug-grid & {
    //  background: susy-svg-grid() no-repeat scroll;
    //
    //  @each $breakpoint in map-keys($gridConfigs) {
    //    @include respond-to-susy($breakpoint) {
    //      background: susy-svg-grid() no-repeat scroll;
    //    }
    //  }
    //}
  }

  &__grid {
    //@include grid-container();
  }
}
