.o-thank-you {
  background: get-color(yellow);
  @include flex-column(null, center);
  flex-grow: 1;
  overflow: auto;
  &__audio_counter-wrapper{
    font-family: 'DidactGothic', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.9375rem;
    text-align: center;
    letter-spacing: 0.065em;
    color: #31489b;
    display: flex;
    justify-content: center;
  }
  &__content {
    opacity: 0;
    max-width: 375px;
    transition: all 0.3s ease;
    margin: 80px 0;
    &-title {
      font-family: 'DidactGothic', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      letter-spacing: 0.065em;
      color: #31489b;
      width: 290px;
      margin: 0 auto;
      margin-bottom: 27px;
    }
    @include mq(desktop) {
      max-width: 1024px;
      width: 100%;
      @include flex-column(center);
    }
  }

  &__title {
    display: block;
    font-family: 'DidactGothic', sans-serif;
    color: #31489b;
    font-weight: normal;
    font-size: 64px;
    line-height: 50px;
    padding: 0 50px 0 15px;
    margin-top: 25px;
    margin-bottom: 30px;

    @include mq(desktop) {
      margin-left: 170px;
      font-size: 112px;
      line-height: 80px;
    }

    span {
      display: block;
      text-align: right;
      margin-right: -40px;

      @include mq(desktop) {
        margin-right: -25px;
      }
    }
  }
  &__text {
    font-family: 'DidactGothic', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    padding: 0 15px 0 15px;
    text-transform: lowercase;
    color: #31489b;
    text-align: right;
    line-height: initial;
    white-space: nowrap;
    margin-top: 20px;
    @include mq(desktop) {
      font-size: 26px;
      margin-top: 35px;
      text-align: center;
    }
  }

  &__button {
    cursor: pointer;
    @include flex-column(center, center);
    width: 131px;
    height: 131px;
    border-radius: 50%;
    border: none;
    background: get-color(blue);
    outline: none;
    margin: 0 37px;

    svg {
      height: 130px;
    }

    &:first-child {
      margin-right: 22px;

      @include mq(desktop) {
        margin-right: 60px;
      }

      svg {
        margin-left: 5px;
      }
    }
    &-left,
    &-right {
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      svg{
        fill: #2C2D64;
      }
    }
   
  }
  
  &__buttons {
    @include flex(center);
    margin-bottom: 20px;
    margin-top: 20px;
    min-height: 71px;

    @include mq(desktop) {
      align-items: center;
      margin-top: 60px;
    }

    &.invisible {
      button {
        display: none;
      }
    }
  }

  &__link-button {
    @include flex(center, center);
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    letter-spacing: 0.065em;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    color: #3a3b84;
    margin: 0 50px 0;
    width: 242px;
    min-height: 76px;
    background: get-color(blue);
    border: none;
    outline: none;
    &:last-child {
      margin-top: 20px;
    }
    &:not(&:last-child) {
      margin: 20px 50px 20px;
    }

    @include mq(desktop) {
      max-width: 274px;
      max-height: 89px;
      margin: 0 20px 20px;
      &:last-child {
        margin-top: 0;
      }
    }
  }

  &__actions {
    margin-top: 80px;

    @include mq(desktop) {
      width: 100%;
      margin-top: 120px;
      @include flex(center, center);
    }
  }
  .animation-wrapper {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    z-index: 98;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
  .thanks-animation {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #eeb92c;
    width: 355px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__progress {
      height: 8px;
      width: 100%;
      position: relative;
      overflow: hidden;
      background: #94b6c5;
      .progress {
        position: absolute;
        bottom: 0;
        left: 0;
        top: 0;
        background: #31489b;
        height: 100%;
        width: 1%;
        transition: all 0.1s ease;
      }
    }
    &__title {
      font-family: 'DidactGothic', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 62px;
      line-height: 50px;
      text-transform: lowercase;
      color: #31489b;
      padding: 0 20px;
      span {
        display: block;
        text-align: right;
      }
    }
    &__description {
      font-family: 'DidactGothic', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 50px;
      text-transform: lowercase;
      color: #31489b;
    }
  }

  &__texts {
    max-width: 315px;
    margin: 0 auto;
    &-title {
      margin-top: 135px;

      font-family: 'DidactGothic';
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      letter-spacing: 0.065em;
      color: #31489b;
      margin-bottom: 20px;
    }
    &-wrapper{
      padding: 0 20px;
      max-width: 375px;
      margin: 0 auto;
    }
    &-item {
      font-family: 'DidactGothic';
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 31px;
      color: #2c2d64;
      padding: 16px 16px 16px 29px;
      background: #dbdcdd;
      position: relative;
      border-radius: 8px;
      margin-bottom: 24px;

      &:before {
        content: '';
        position: absolute;
        left: -15px;
        top: 0;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-right: 30px solid #dbdcdd;
        border-bottom: 15px solid transparent;
      }
    }
  }
  .dissabled{
    opacity: .2;
    user-select: none;
    cursor: default;
  }

  .load-more{
    cursor: pointer;
    margin-top: 10px;
    font-family: 'DidactGothic';
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.9375rem;
    text-align: center;
    letter-spacing: 0.065em;
    color: #31489b;
    opacity: 1;
  }
}
