// Colors
$colors-default: (
  accent-primary: #000000,
  accent-secondary: #00ff00,
  blue: #98bac9,
  yellow: #EEB92C,
  purple: #2c2d64,
  white: #fff,
  gray: #dbdcdd
);

/**
 * Returns color from settings referenced by id
 * @param  {string} $id Identifier for the color in the configuration map
 * @param  {map} $colors Map with colors
 * @return {number} Resulting color from configuration file
 */
@function get-color($id, $colors: $colors-default) {
  @if not(map-has-key($colors, $id)) {
    @error 'Color #{$id} not known';
  }

  @return map-get($colors, $id);
}
