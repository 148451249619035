// 3rd Party Frameworks
@import '~sanitize.css/sanitize';
//@import '~susy/sass/susy';
//@import '~susy/sass/plugins/svg-grid';
@import '~sass-mq/mq';
@import '~beerslider/dist/BeerSlider.css';

// Settings
@import 'settings/colors';
@import 'settings/breakpoints';
@import 'settings/spacings';
@import 'settings/typo';
@import 'settings/z-index';
//@import 'settings/grid';
@import 'settings/fonts';

// Tools
@import 'tools/functions';
@import 'tools/mixins';
@import 'tools/text-types';

@import 'generic/base';
@import '../components/organisms/accordion/accordion';
@import '../components/organisms/intro/intro';
@import '../components/organisms/audio-recorder/audio-recorder';
@import '../components/organisms/first-screen/first-screen';
@import '../components/organisms/miphs/miphs';
@import '../components/organisms/about/about';
@import '../components/organisms/adm/adm';
@import '../components/organisms/adm-sound/adm-sound';
@import '../components/organisms/statistic/statistic';



@import '../components/organisms/thank-you/thank-you';
@import '../components/organisms/slide/slide';

@import '../components/organisms/button/button';
@import '../components/organisms/header/header';
@import '../components/layouts/container/container';

[type=range]::-webkit-slider-thumb{
  -webkit-appearance: none !important;
  height: 48px;
  width: 80px;
  border-radius: 2px;
  display: block;
}

.beer-handle {
  background: rgba(0, 0, 0, .7);
  color: white;
}

.beer-range:focus~.beer-handle {
  background: rgba(0, 0, 0, .7);
  box-shadow: 0 0 3px rgba(0,0,0,.4);
}

.beer-slider {
  @include mq(tablet) {
    img {
      max-width: 400px;
    }
    margin-bottom: -4px;
  }
}

.beer-reveal {
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
