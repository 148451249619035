.first-screen {
  width: 100vw;
  height: 100vh;
  background: #dbdcdd;
  background-size: cover;
  font-family: 'DidactGothic', sans-serif;
}

.o-first-screen {
  @include flex-column(center);
  flex-grow: 1;
  margin: 0 auto;

  &__content {
    padding-top: 50px;
    max-width: 375px;
    margin: 0 auto;
    flex-grow: 1;
    @include flex-column(center);

    @include mq($from: tablet) {
      max-width: 100%;
    }

    .text-composition {
      font-family: 'DidactGothic', sans-serif;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: 81px;
      line-height: 50px;
      text-transform: lowercase;
      color: #ee4323;
      max-width: 350px;
      margin-bottom: 100px;
      @include mq(desktop) {
        max-width: 100%;
        font-size: 147px;
        line-height: 85px;
        font-weight: normal;
      }

      .first {
        margin-left: auto;
      }

      span {
        margin-bottom: 7px;
      }
    }
    .mobile-composition {
      @include mq(desktop) {
        display: none;
      }
    }
    .desktop-composition {
      display: none;
      .spacing {
        margin-left: 100px;
      }
      @include mq(desktop) {
        display: flex;
      }
      .bottom-content {
        @include flex(space-between, center);
        margin-top: 60px;
      }
    }
    .text-description {
      margin-left: 4px;
      margin-top: 8px;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: #2c2d64;
      max-width: 275px;
      font-family: 'Montserrat', sans-serif;
      @include mq(desktop) {
        font-size: 31px;
        line-height: 38px;
        font-weight: normal;
        margin-top: 0;
        max-width: 100%;
        .description-spacing {
          margin-left: 100px;
        }
        span {
          display: block;
          text-align: left;
        }
      }
    }
  }
  &__link {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.065em;
    text-transform: uppercase;

    color: #2c2d64;
    border: none;
    outline: none;
    cursor: pointer;
    width: 274px;
    min-height: 115px;
    letter-spacing: 0.065em;

    border-radius: 3px;
    background: get-color(blue);
    font-family: Montserrat, sans-serif;
    margin: 0 auto 35px;

    @include mq(desktop) {
      margin: 0;
      @include flex(null, center);
    }
  }
}
