.o-link {
  position: relative;
  width: 275px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 24px 40px;
  background: get-color(blue);
  border-radius: 3px;
  font-family: 'Montserrat', sans-serif;
  border: none;
  outline: none;
  cursor: pointer;

  .text {
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
    color: #2c2d64;
  }
}

.button {
  width: auto;
  height: auto;
  padding: 10px 35px;
}

.smaller {
  .text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
}

.margin-m {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}
