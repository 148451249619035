.o-accordion {
  &__author {
    display: inline-block;
    padding: 7px 14px;
    background: #94b6c5;
    font-family: 'MontserratLight', sans-serif;
    font-style: italic;
    font-weight: 300;
    color: #000000;
  }

  &__accordion {
    margin: 0;
  }
  .item-wrapper {
    margin-bottom: 40px;
  }
  &__control {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #ceced6;
    width: 100%;
    max-width: 350px;
    background: #ee4323;
    box-shadow: none;
    border: none;
    padding: 6px 6px 20px 10px;
    text-align: left;
    position: relative;
    outline: none;
    @include mq($from: tablet) {
      max-width: 714px;
      width: 100%;
      padding: 18px 66px 18px 24px;
    }

    &[aria-expanded='true'] {
      &::before {
        transform: rotate(180deg) translate(0, 5px);
        @include mq($from: tablet) {
          transform: rotate(180deg) translate(0, -15px);
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      background-image: url('/dummy/images/arrow.svg');
      width: 40px;
      height: 40px;
      bottom: -25px;
      right: 0;
      transition: all 0.3s ease;
      outline: none;
      @include mq($from: tablet) {
        bottom: 50%;
        right: 15px;
        transform: translate(0, 50%);
      }
    }
  }
  &__panel {
    font-family: 'MontserratLight';
    width: 335px;
    border: 3px solid #ee4323;
    margin: 0;
    font-weight: lighter;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    padding: 14px 24px 17px 16px;
    @include mq($from: tablet) {
      max-width: 714px;
      width: 100%;
      border-width: 1px;
      padding: 20px 24px;
    }
    span {
      display: block;
      margin-bottom: 15px;
      a{
        border-bottom: 1px solid #000;
      }
      b {
        font-family: 'Montserrat';
        font-weight: bold;
      }
    }
  }
}
