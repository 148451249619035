// keepfile
@mixin flex($justify: null, $align: null) {
  display: flex;
  flex-wrap: wrap;
  @if ($justify) {
    justify-content: $justify;
  }
  @if ($align) {
    align-items: $align;
  }
}

@mixin flex-column($justify: null, $align: null) {
  display: flex;
  flex-direction: column;
  @if ($justify) {
    justify-content: $justify;
  }
  @if ($align) {
    align-items: $align;
  }
}

$mq-responsive: true;
$mq-breakpoints: (
  mobile: 320px,
  tablet: 768px,
  desktop: 1024px,
  wide: 1300px
);
