@font-face {
  font-family: 'DidactGothic';
  src: url('/ummy/fonts/DidactGothic/DidactGothic-Regular.ttf') format('ttf'),
    url('/dummy/fonts/DidactGothic/DidactGothic-Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/dummy/fonts/Montserrat/Montserrat-Medium.woff') format('ttf'),
    url('/dummy/fonts/Montserrat/Montserrat-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratLight';
  src: url('/dummy/fonts/Montserrat/Montserrat-Light.woff') format('ttf'),
    url('/dummy/fonts/Montserrat/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
