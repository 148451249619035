.o-slide {
  position: absolute;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include flex-column(center);
  z-index: 1;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;

  &__content {
    transition: all 0.1s linear;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    flex-grow: 1;
    @include flex-column;
  }

  &--active {
    overflow: auto;
    z-index: 10;
    opacity: 1;
    visibility: visible;

    .o-slide__content {
      opacity: 1;
    }
  }

  &--hidden {
    .o-slide__content {
      opacity: 0;
    }
  }
}
